// bookApi.ts

import { fetchData } from "../helpers/api_helper";

// Define the structure for an Episode
export interface Episode {
  id: number;
  title: string;
  description: string;
  videoLink: string | null;
  audioLink: string | null;
  videoLinkString: string | null;
  audioLinkString: string | null;
  coverImage: string | null;
  story: string;
  overallGlobalReadersCount: number;
  createdAt: string;
  updatedAt: string;
  bookId: number;
}

// Define the structure for a Book
export interface Book {
  id: number;
  title: string;
  description: string;
  videoLink: string | null;
  audioLink: string | null;
  videoLinkString: string | null;
  audioLinkString: string | null;
  coverImage: string | null;
  bookDownloadLink: string | null;
  overallGlobalReadersCount: number;
  type: string;
  createdAt: string;
  updatedAt: string;
  userId: number;
  episodes: Episode[];
}

// Define the structure for the API response
interface GetBooksResponse {
  status: string;
  data: {
    books: Book[];
  };
}

// Function to fetch all books
export const getAllBooks = async (): Promise<Book[]> => {
  try {
    const response: GetBooksResponse = await fetchData("/api/v1/books/getAllBooks");
    return response.data.books;
  } catch (error) {
    console.error("Failed to fetch books:", error);
    throw error;
  }
};
