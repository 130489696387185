import { fetchData } from "../helpers/api_helper";

// Define the structure for About information
export interface AboutInfo {
  id: number;
  content: string;
  aboutMeImages: string;
  aboutMeImageOne: string;
  email: string;
  phoneNumber: string;
  address: string;
  socialNetworkLink: string; // JSON string that can be parsed
  createdAt: string;
  updatedAt: string;
  userId: number | null;
}

// Define the structure for the API response for About
interface GetAboutResponse {
  status: string;
  data: {
    about: AboutInfo;
  };
}

// Function to fetch About information
export const getAboutInfo = async (): Promise<AboutInfo> => {
  try {
    const response: GetAboutResponse = await fetchData(`/api/v1/abouts`);
    return response.data.about; // Access about directly
  } catch (error) {
    console.error("Failed to fetch About information:", error);
    throw error;
  }
};
