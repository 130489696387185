// src/components/Index.tsx
import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Layout from '../Layout';

// Import authProtectedRoutes only
import { authProtectedRoutes } from './allRoutes';

const Index = () => {
  return (
    <Routes>
      {/* Protected routes only */}
      {authProtectedRoutes.map((route, idx) => (
        <Route
          path={route.path}
          element={<Layout>{route.component}</Layout>}
          key={idx}
        />
      ))}

      {/* Fallback route if no match */}
      <Route path="*" element={<Navigate to="/signin" />} />
    </Routes>
  );
};

export default Index;
