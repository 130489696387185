// src/components/AboutStatistics.tsx
import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { getAllBooks } from '../../Common/realBackend/getAllBooks'; // Import the API function

const AboutStatistics: React.FC = () => {
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 576);
  const [globalReaders, setGlobalReaders] = useState<number>(0);
  const [storiesCount, setStoriesCount] = useState<number>(0);
  const [displayedReaders, setDisplayedReaders] = useState<number>(0);
  const [displayedStories, setDisplayedStories] = useState<number>(0);

  // Update isMobile based on screen width
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 576);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Fetch books and calculate statistics
  useEffect(() => {
    const fetchStatistics = async () => {
      try {
        const books = await getAllBooks();
        let totalReaders = 0;
        let totalStories = 0;

        books.forEach((book) => {
          totalReaders += book.overallGlobalReadersCount;
          if (book.type === 'series') {
            totalStories += book.episodes.length;
          } else {
            totalStories += 1;
          }
        });

        setGlobalReaders(totalReaders);
        setStoriesCount(totalStories);
      } catch (error) {
        console.error("Error fetching books data:", error);
      }
    };

    fetchStatistics();
  }, []);

  // Count effect for global readers
  useEffect(() => {
    let count = 0;
    const duration = 2000; // Duration in milliseconds for the count effect
    const stepTime = Math.abs(Math.floor(duration / globalReaders));
    
    const interval = setInterval(() => {
      if (count < globalReaders) {
        count += 1;
        setDisplayedReaders(count);
      } else {
        clearInterval(interval);
      }
    }, stepTime);

    return () => clearInterval(interval);
  }, [globalReaders]);

  // Count effect for stories count
  useEffect(() => {
    let count = 0;
    const duration = 2000; // Duration in milliseconds for the count effect
    const stepTime = Math.abs(Math.floor(duration / storiesCount));
    
    const interval = setInterval(() => {
      if (count < storiesCount) {
        count += 1;
        setDisplayedStories(count);
      } else {
        clearInterval(interval);
      }
    }, stepTime);

    return () => clearInterval(interval);
  }, [storiesCount]);

  return (
    <Row className="my-5 align-items-start">
      {/* Left Section - Global Readers */}
      <Col xs={6} md={6} className="text-center">
        <div
          className="statistic"
          style={{
            color: 'transparent',
            background: 'linear-gradient(135deg, #981C82 0%, #B07900 100%)',
            backgroundClip: 'text',
            WebkitBackgroundClip: 'text',
            padding: '20px',
            height: isMobile ? '150px' : '200px',
            width: '80%',
            margin: '0 auto',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: isMobile ? 'flex-start' : 'center',
            alignItems: 'center'
          }}
        >
          <h2 className="my-0" style={{ fontSize: isMobile ? '4rem' : '7rem', fontWeight: 'bold' }}>{displayedReaders}</h2>
          <p className="mb-0" style={{ fontSize: isMobile ? '1rem' : '1.5rem', color: '#9F9F9F' }}>Readers</p>
        </div>
      </Col>

      {/* Divider Line */}
      {!isMobile && (
        <Col md={1} className="text-center align-self-center">
          <div
            style={{
              height: '150px',
              width: '2%',
              backgroundColor: '#9F9F9F',
              margin: 'auto',
              display: 'block',
            }}
          />
        </Col>
      )}

      {/* Right Section - Stories */}
      <Col xs={6} md={5} className="text-center">
        <div
          className="statistic"
          style={{
            color: 'transparent',
            background: 'linear-gradient(135deg, #981C82 0%, #B07900 100%)',
            backgroundClip: 'text',
            WebkitBackgroundClip: 'text',
            padding: '20px',
            height: isMobile ? '150px' : '200px',
            width: '80%',
            margin: '0 auto',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: isMobile ? 'flex-start' : 'center',
            alignItems: 'center'
          }}
        >
          <h2 className="my-0" style={{ fontSize: isMobile ? '4rem' : '7rem', fontWeight: 'bold' }}>{displayedStories}</h2>
          <p className="mb-0" style={{ fontSize: isMobile ? '1rem' : '1.5rem', color: '#9F9F9F' }}>Stories</p>
        </div>
      </Col>

      {/* Mobile View Adjustments */}
      <style>
        {`
          @media (max-width: 767.98px) { /* Bootstrap's sm breakpoint */
            .statistic {
              padding: 10px;
              height: 150px;
            }
            h2 {
              font-size: 4rem;
            }
            p {
              font-size: 1rem;
            }
          }
        `}
      </style>
    </Row>
  );
};

export default AboutStatistics;
