import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { getAboutInfo, AboutInfo } from '../../Common/realBackend/getAbout';

const AboutContent: React.FC = () => {
  const [aboutData, setAboutData] = useState<AboutInfo | null>(null);
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 576);

  // Fetch the "About" data from the API
  useEffect(() => {
    const fetchAboutData = async () => {
      try {
        const data = await getAboutInfo();
        setAboutData(data);
      } catch (error) {
        console.error("Failed to fetch About data:", error);
      }
    };
    fetchAboutData();
  }, []);

  // Update isMobile based on screen width
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 576);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (!aboutData) {
    return <div>Loading...</div>;
  }

  const { content, aboutMeImages, aboutMeImageOne, email, phoneNumber, address, socialNetworkLink } = aboutData;

  // Safely parse social network links with a fallback
  let socialLinks: Record<string, string> = {};
  try {
    socialLinks = JSON.parse(socialNetworkLink) as Record<string, string>;
  } catch (error) {
    console.error("Failed to parse socialNetworkLink JSON:", error);
  }

  return (
    <Row className="mb-5 pb-5 position-relative">
      {/* Left Section - Text */}
      <Col md={6} className="d-flex flex-column justify-content-center">
        <div>
          <h1 className="nba-font" style={{
            fontSize: isMobile ? '6rem' : '10rem',
            color: 'white',
            textAlign: isMobile ? 'center' : 'left',
            marginTop: isMobile ? '0' : '1rem',
          }}>ABOUT ME</h1>

          {/* Display content from API */}
          <p className="large-paragraph mb-4" style={{
            fontSize: isMobile ? '1rem' : '1.2rem',
            color: 'white',
            textAlign: isMobile ? 'center' : 'left',
            marginTop: isMobile ? '0' : '1rem',
          }}>
            {content}
          </p>

        </div>
      </Col>

      {/* Right Section - Images */}
      <Col md={6} className="position-relative d-flex justify-content-end">
        <div style={{ position: 'relative', width: 'fit-content' }}>
          {/* Display images from API, with fallback images if not available */}
          {aboutMeImageOne && (
            <img 
              src={aboutMeImageOne} 
              alt="Second Background Image" 
              className="img-fluid" 
              style={{
                position: 'absolute',
                top: '30px',
                right: '-30px',
                maxHeight: '380px',
                width: 'auto',
                zIndex: 0,
              }} 
            />
          )}

          {aboutMeImages && (
            <img 
              src={aboutMeImages}
              alt="About Us Image" 
              className="img-fluid" 
              style={{ 
                maxHeight: '380px', 
                width: 'auto',
                zIndex: 1,
                position: 'relative'
              }} 
            />
          )}
        </div>
      </Col>

      {/* Mobile View Adjustments */}
      <style>
        {`
          @media (max-width: 767.98px) {
            h1 {
              font-size: 4rem;
            }
            .large-paragraph {
              font-size: 1rem;
            }
          }
        `}
      </style>
    </Row>
  );
};

export default AboutContent;
