import { fetchData } from "../helpers/api_helper";

// Define the structure for an Amazon Book
export interface AmazonBook {
  id: number;
  title: string;
  author?: string;
  coverImage: string;
  amazonLink: string;
  amazonLinkTwo?: string; // Add amazonUKLink as an optional property
  description: string;
  publishedDate?: string;
}

// Define the structure for the API response for Amazon books
interface GetAmazonBooksResponse {
  status: string;
  data: {
    amazonBook: AmazonBook[]; // Updated to match API response
  };
}

// Function to fetch all Amazon books
export const getAllAmazonBooks = async (): Promise<AmazonBook[]> => {
  try {
    const response: GetAmazonBooksResponse = await fetchData(`/api/v1/books/getAllAmazonBooks`);
    return response.data.amazonBook; // Access amazonBook directly
  } catch (error) {
    console.error("Failed to fetch Amazon books:", error);
    throw error;
  }
};
