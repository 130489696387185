import { fetchData } from "../helpers/api_helper";

// Define the structure for the contact payload
interface ContactPayload {
  name: string;
  email: string;
  subject: string;
  message: string;
}

// Define the structure for the API response
interface ContactResponse {
  status: string;
  message: string;
}

// Function to create a contact
export const createContact = async (payload: ContactPayload): Promise<ContactResponse> => {
  try {
    // Call fetchData with the appropriate URL and payload
    const response: ContactResponse = await fetchData("/api/v1/contacts/createContact", {
      method: "POST",
      body: JSON.stringify(payload),
      headers: { "Content-Type": "application/json" }
    });
    return response;
  } catch (error) {
    console.error("Failed to create contact:", error);
    throw error;
  }
};
