// api_helper.ts

const BASE_URL = "https://bookstory.onrender.com";

// Define the structure for the options parameter
interface FetchOptions extends RequestInit {
  headers?: HeadersInit;
}

export const fetchData = async (endpoint: string, options: FetchOptions = {}) => {
  // Retrieve the entire authResponse from local storage
  const storedResponse = localStorage.getItem('authResponse');
  let token: string | null = null;

  // If authResponse exists, parse it to get the token
  if (storedResponse) {
    const authResponse = JSON.parse(storedResponse);
    token = authResponse.token; // Extract the token
  }

  const headers = {
    "Content-Type": "application/json",
    ...(token && { Authorization: `Bearer ${token}` }) // Include the token in the headers if it exists
  };

  try {
    const response = await fetch(`${BASE_URL}${endpoint}`, {
      ...options,
      headers: {
        ...headers,
        ...(options.headers || {}), // Merge any additional headers provided in options
      },
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status} ${response.statusText}`);
    }
    return await response.json();
  } catch (error) {
    console.error("API call failed:", error);
    throw error;
  }
};
