// Common/realBackend/checkAuth.ts
export const checkAuth = (onNotAuthenticated: () => void) => {
  const storedResponse = localStorage.getItem('authResponse');

  if (!storedResponse) {
    alert("You are not logged in. Please log in to comment.");
    onNotAuthenticated(); // Trigger the modal
    return false; // Indicates user is not authenticated
  }

  return true; // Indicates user is authenticated
};
