import React, { useState, useEffect } from 'react';
import { Form, Button, ListGroup, Spinner } from 'react-bootstrap';
import { createComment } from '../../Common/realBackend/createComment';
import { getComments, CommentResponse } from '../../Common/realBackend/getComments';
import { createReply, ReplyResponse } from '../../Common/realBackend/createReply';
import { checkAuth } from '../../components/CheckAuth'; // Import the checkAuth function

import LoginModal from '../Auth/loginModal';
import SignUpModal from '../Auth/signUpModal';

interface Comment {
  id: number;
  content: string;
  replies: Comment[];
  timestamp: string;
  createdAt: string;
  isExpanded?: boolean;
  user?: {
    username: string;
    role?: string; // Optional, depending on what other fields you expect
  };
  User?: { // Optional, if the API response has `User` field.
    username: string;
  };
}

interface CommentSectionProps {
  bookId: number;
  referenceType: 'one-short' | 'series';
}

const CommentSection: React.FC<CommentSectionProps> = ({ bookId, referenceType }) => {
  const [comments, setComments] = useState<Comment[]>([]);
  const [commentText, setCommentText] = useState('');
  const [replyText, setReplyText] = useState<string | null>(null);
  const [replyToId, setReplyToId] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isReplyLoading, setIsReplyLoading] = useState<number | null>(null);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showSignUpModal, setShowSignUpModal] = useState(false);

  useEffect(() => {
    fetchComments();
  }, []);

  const formatDate = (date: string): string => {
    const now = new Date();
    const commentDate = new Date(date);
    const today = new Date(now.setHours(0, 0, 0, 0));
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    if (commentDate >= today) {
      return `Today at ${commentDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })}`;
    } else if (commentDate >= yesterday) {
      return `Yesterday at ${commentDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })}`;
    } else {
      return commentDate.toLocaleString([], { dateStyle: 'medium', timeStyle: 'short' });
    }
  };

  const fetchComments = async () => {
    try {
      const response: CommentResponse = await getComments(String(bookId), referenceType);
      const fetchedComments = response.data?.comments?.map((comment) => ({
        ...comment,
        timestamp: formatDate(comment.createdAt),
        username: comment.User.username,
        isExpanded: false,
        replies: comment.replies || [], // Ensure replies is always an array
      })) || [];
      setComments(fetchedComments);
    } catch (error) {
      console.error('Error fetching comments:', error);
    }
  };

  const handleCommentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCommentText(e.target.value);
  };

  const handleReplyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setReplyText(e.target.value);
  };

  const handleCommentSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!checkAuth(() => setShowLoginModal(true))) return; // Pass a callback to show the modal
    await submitComment();
  };

  const submitComment = async () => {
    const payload = {
      content: commentText,
      referenceType: referenceType,
      referenceId: String(bookId),
    };

    try {
      setIsLoading(true);
      const response = await createComment(payload.referenceId, payload);

      if (response.status === 'success') {
        const newComment: Comment = {
          id: comments.length + 1,
          content: commentText,
          replies: [],
          timestamp: formatDate(new Date().toISOString()),
          createdAt: new Date().toISOString(),
          user: response.data.user,
        };
        setComments([...comments, newComment]);
        setCommentText('');
      }
    } catch (error: any) {
      if (error.response?.status === 401) {
        alert("Session expired. Please comment again.");
        localStorage.removeItem('authResponse');
        setShowLoginModal(true);
      } else {
        console.error('Error submitting comment:', error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleReplySubmit = async (e: React.FormEvent<HTMLFormElement>, commentId: number) => {
    e.preventDefault();
    if (!checkAuth(() => setShowLoginModal(true))) return; // Check if the user is authenticated
    if (replyText && replyToId !== null) {
      const payload = { content: replyText };
      try {
        setIsReplyLoading(commentId);
        const response: ReplyResponse = await createReply(String(commentId), payload);

        if (response.status === 'success') {
          const updatedComments = comments.map((comment) => {
            if (comment.id === replyToId) {
              return {
                ...comment,
                replies: [
                  ...comment.replies,
                  {
                    id: Date.now(),
                    content: replyText,
                    replies: [],
                    timestamp: formatDate(new Date().toISOString()),
                    createdAt: new Date().toISOString(),
                    user: response.data.user,
                  },
                ],
              };
            }
            return comment;
          });

          setComments(updatedComments);
          setReplyText(null);
          setReplyToId(null);
        }
      } catch (error) {
        console.error('Error submitting reply:', error);
      } finally {
        setIsReplyLoading(null);
      }
    }
  };

  const initiateReply = (commentId: number) => {
    if (!checkAuth(() => setShowLoginModal(true))) return; // Check if the user is authenticated
    setReplyToId(commentId);
  };

  const toggleReplies = (commentId: number) => {
    const updatedComments = comments.map((comment) => {
      if (comment.id === commentId) {
        return { ...comment, isExpanded: !comment.isExpanded };
      }
      return comment;
    });
    setComments(updatedComments);
  };

  const renderComments = (commentsList: Comment[]) => {
    return commentsList.map((comment) => (
      <ListGroup.Item key={comment.id} className="border-0 mb-3">
        <div className="w-100">
          <div className="d-flex justify-content-between">
            <span className="text-muted">
              {comment.timestamp} By {comment.User?.username || 'Anonymous'}
            </span>
            <Button variant="link" onClick={() => initiateReply(comment.id)}>
              Reply
            </Button>
          </div>
          <p className="mb-1">{comment.content}</p>
          <Form onSubmit={(e) => handleReplySubmit(e, comment.id)} className="mt-2">
            <Form.Group controlId={`replyInput-${comment.id}`}>
              <Form.Control
                type="text"
                placeholder="Add a reply..."
                value={replyToId === comment.id ? replyText || '' : ''}
                onChange={handleReplyChange}
                style={{ display: replyToId === comment.id ? 'block' : 'none' }}
              />
            </Form.Group>
            {replyToId === comment.id && (
              <Button className="custom-btn mt-2" type="submit" disabled={isReplyLoading === comment.id}>
                {isReplyLoading === comment.id ? (
                  <>
                    <Spinner animation="border" size="sm" /> Submitting...
                  </>
                ) : (
                  'Submit Reply'
                )}
              </Button>
            )}
          </Form>
          {comment.replies?.length > 0 && (
            <>
              <Button
                variant="link"
                className="mt-2"
                onClick={() => toggleReplies(comment.id)}
              >
                {comment.isExpanded ? 'Hide Replies ▲' : 'Show Replies ▼'}
              </Button>
              {comment.isExpanded && (
                <div className="mt-2" style={{ paddingLeft: '20px' }}>
                  {renderComments(comment.replies || [])}
                </div>
              )}
            </>
          )}
        </div>
      </ListGroup.Item>
    ));
  };

  return (
    <div>
      <h4>Comments</h4>
      <Form onSubmit={handleCommentSubmit} className="mb-3">
        <Form.Group controlId="commentInput" className="w-100">
          <Form.Control
            type="text"
            placeholder="Add a comment..."
            value={commentText}
            onChange={handleCommentChange}
            className="w-100"
          />
        </Form.Group>
        <Button className="custom-btn mt-2" type="submit" disabled={isLoading}>
          {isLoading ? (
            <>
              <Spinner animation="border" size="sm" /> Submitting...
            </>
          ) : (
            'Publish'
          )}
        </Button>
      </Form>
      <ListGroup>{renderComments(comments)}</ListGroup>

      <LoginModal
        show={showLoginModal}
        onHide={() => setShowLoginModal(false)}
        onLoginSuccess={() => {
          setShowLoginModal(false); // Close the modal 
          submitComment(); // Automatically submit the comment 
        }} 
        openSignUpModal={() => {
          setShowLoginModal(false);
          setShowSignUpModal(true);
        }}
      /> 

      <SignUpModal
        show={showSignUpModal}
        onHide={() => setShowSignUpModal(false)}
        openLoginModal={() => {
          setShowSignUpModal(false);
          setShowLoginModal(true);
        }}
      />
      
    </div>
  );
};

export default CommentSection;