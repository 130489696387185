import { fetchData } from "../helpers/api_helper";

// Define the structure for the subscriber payload
interface SubscriberPayload {
  email: string;
  firstName: string;
}

// Define the structure for the API response
interface SubscribeResponse {
  status: string;
  message: string;
}

// Function to subscribe to the newsletter
export const subscribeToNewsletter = async (payload: SubscriberPayload): Promise<SubscribeResponse> => {
  try {
    // Adjust the fetchData call according to its expected parameters
    const response: SubscribeResponse = await fetchData("/api/v1/subscribers/subscribe", {
      method: "POST",
      body: JSON.stringify(payload),
      headers: { "Content-Type": "application/json" }
    });
    return response;
  } catch (error) {
    console.error("Failed to subscribe to newsletter:", error);
    throw error;
  }
};