// src/pages/Home.tsx
import React from 'react';
import { Container, Row } from 'react-bootstrap';
import HomeContent from './HomeContent';
import TwoCardLayout from './TwoCardLayout';
import ContactUs from './ContactUs';
import NewsletterSubscription from './NewsletterSubscription'; // Import the new component

const Home = () => {
  return (
    <Container fluid className="py-4">
      <Row className="justify-content-between align-items-center" style={{ marginTop: '0' }}>
        <HomeContent />
      </Row>
      <Row className="justify-content-center">
        <TwoCardLayout />
      </Row>
      <Row className="justify-content-center mt-5">
        <NewsletterSubscription /> {/* Add the subscription component */}
      </Row>
      <Row id="contact-us">
        <ContactUs />
      </Row>
    </Container>
  );
};

export default Home;
