// src/layout/Footer.tsx
import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaLinkedin, FaYoutube } from 'react-icons/fa';
import { getAboutInfo, AboutInfo } from '../Common/realBackend/getAbout'; 

const Footer: React.FC = () => {
  const [socialLinks, setSocialLinks] = useState({ linkedin: '', youtube: '' });

  useEffect(() => {
    const fetchSocialLinks = async () => {
      try {
        const aboutInfo: AboutInfo = await getAboutInfo();
        const socialNetwork = JSON.parse(aboutInfo.socialNetworkLink); // Parse JSON string for links
        setSocialLinks({
          linkedin: socialNetwork.linkedin || '',
          youtube: socialNetwork.youtube || '',
        });
      } catch (error) {
        console.error("Error fetching social links:", error);
      }
    };

    fetchSocialLinks();
  }, []);

  return (
    <footer className="py-3" style={footerStyles}>
      <Container>
        <Row className="align-items-center">
          {/* Left side: Copyright text */}
          <Col md={6} className="text-md-start text-center mb-2 mb-md-0">
            <p style={copyrightStyles}>
              &copy; 2024 Adebowale. All Rights Reserved.
            </p>
          </Col>

          {/* Right side: Social icons */}
          <Col md={6} className="text-md-end text-center">
            {/* LinkedIn Icon */}
            <a
              href={socialLinks.linkedin || "https://www.linkedin.com"}
              target="_blank"
              rel="noopener noreferrer"
              className="d-inline-block me-3"
            >
              <div className="d-flex justify-content-center align-items-center" style={circleStyles}>
                <FaLinkedin size={15} color="#111827" />
              </div>
            </a>

            {/* YouTube Icon */}
            <a
              href={socialLinks.youtube || "https://www.youtube.com"}
              target="_blank"
              rel="noopener noreferrer"
              className="d-inline-block"
            >
              <div className="d-flex justify-content-center align-items-center" style={circleStyles}>
                <FaYoutube size={15} color="#111827" />
              </div>
            </a>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

// Footer background and padding styles
const footerStyles: React.CSSProperties = {
  backgroundColor: '#111827',
  color: '#fff',
};

// Copyright text styles
const copyrightStyles: React.CSSProperties = {
  margin: 0,
  fontSize: '0.9rem',
};

// Styles for the icon circle
const circleStyles: React.CSSProperties = {
  backgroundColor: '#fff',
  borderRadius: '50%',
  width: '30px',
  height: '30px',
  display: 'inline-flex',
};

export default Footer;
