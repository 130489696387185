// src/layout/Layout.tsx
import React, { ReactNode } from 'react';
import Header from './Header';
import { Container } from 'react-bootstrap';
import Footer from './Footer';
import { useLocation } from 'react-router-dom';

const Layout = ({ children }: { children: ReactNode }) => {
  const location = useLocation();
  
  // Check if the current path matches Story Details page dynamically
  const isStoryDetailsPage = location.pathname.startsWith('/story-details');

  return (
    <div style={layoutStyles}>
      <Header />

      <div style={lightElementStyles()} />

      <Container
        fluid={isStoryDetailsPage} // Make the container full width for Story Details
        className={`${isStoryDetailsPage ? 'p-0' : 'px-3'} flex-grow-1`} // Apply 'p-0' for no padding in Story Details page
        style={{
          position: 'relative',
          zIndex: 1,
          ...(isStoryDetailsPage ? { margin: 0 } : {}), // Remove margins for Story Details page
        }}
      >
        {children}
      </Container>

      <Footer />
    </div>
  );
};

// Layout styles to use flexbox for sticky footer
const layoutStyles: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh', // Full height of the viewport
  backgroundColor: '#111827',
};

// Light element styles that adjust for mobile responsiveness
const lightElementStyles = (): React.CSSProperties => {
  const isMobile = window.innerWidth <= 576; // Check if the screen is mobile

  return {
    position: 'absolute',
    width: isMobile ? '500px' : '1100px', // Adjust width for mobile (keep it less than 100%)
    height: isMobile ? '300px' : '400px', // Adjust height for mobile
    borderRadius: '50%',
    backgroundColor: '#6C4BEF',
    top: isMobile ? '0px' : '-100px', // Adjust top position for mobile to fit better
    left: isMobile ? '0px' : '-100px', // Adjust left position for mobile to fit better
    zIndex: 0,
    filter: 'blur(250px)',
    transform: isMobile ? 'translate(-50%, -50%)' : 'none', // Center for mobile
  };
};

export default Layout;
