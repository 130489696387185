// src/ShareComponent.tsx
import React from 'react';
import {
  FacebookShareButton,
  TwitterShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  EmailIcon,
} from 'react-share';
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap is imported

interface ShareComponentProps {
  url: string;
  title: string;
}

const ShareComponent: React.FC<ShareComponentProps> = ({ url, title }) => {
  return (
    <div className="d-flex align-items-center"> {/* Flex container to align items */}
      <h5 className="mb-0 me-3">Share On</h5> {/* Removing bottom margin and adding right margin */}
      <style>
        {`
          .share-buttons {
            display: flex;
            gap: 10px; /* Spacing between buttons */
          }
        `}
      </style>

      <div className="share-buttons">
        {/* FacebookShareButton without quote prop */}
        <FacebookShareButton url={url} hashtag="#YourHashtag" className="btn btn-outline-primary">
          <FacebookIcon size={32} round />
        </FacebookShareButton>
        {/* TwitterShareButton */}
        <TwitterShareButton url={url} title={title} className="btn btn-outline-info">
          <TwitterIcon size={32} round />
        </TwitterShareButton>
        {/* EmailShareButton */}
        <EmailShareButton url={url} subject="Check this out!" body={title} className="btn btn-outline-success">
          <EmailIcon size={32} round />
        </EmailShareButton>
      </div>
    </div>
  );
};

export default ShareComponent;
