import React from 'react';
import { Card, Button } from 'react-bootstrap';

interface BookCardProps {
  title: string;
  coverImage: string;
  amazonLink: string;
  amazonUKLink?: string; // Make this optional
}

const BookCard: React.FC<BookCardProps> = ({ title, coverImage, amazonLink, amazonUKLink }) => {
  return (
    <Card className="mb-4">
      <Card.Img variant="top" src={coverImage} alt={title} />
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        <div className="d-flex flex-column flex-sm-row justify-content-between">
          <Button
            variant="outline-success"
            href={amazonLink}
            target="_blank"
            className="mb-2 mb-sm-0" // Adds margin on mobile, removes it on larger screens
          >
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/a/a9/Amazon_logo.svg"
              alt="Get on Amazon"
              style={{ width: '20px', marginRight: '5px' }}
            />
            Get on Amazon
          </Button>

          {amazonUKLink && (
            <Button
              variant="outline-primary"
              href={amazonUKLink}
              target="_blank"
              className="mb-2 mb-sm-0" // Adds margin on mobile, removes it on larger screens
            >
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/a/a9/Amazon_logo.svg"
                alt="Get on Amazon UK"
                style={{ width: '20px', marginRight: '5px' }}
              />
              Get on Amazon UK
            </Button>
          )}
        </div>
      </Card.Body>
    </Card>
  );
};

export default BookCard;
