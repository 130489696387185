// src/StoryContent.tsx
import React from 'react';

interface StoryContentProps {
  content: string;
  fontSize: string; // Accept fontSize prop
  padding: string; // Accept padding prop
}

const StoryContent: React.FC<StoryContentProps> = ({ content, fontSize, padding }) => {
  return (
    <div
      className="story-content-container"
      style={{
        display: 'flex',
        justifyContent: 'center',
        padding: '0 1rem', // Extra padding to prevent touching the edges on small screens
      }}
    >
      <div
        className="story-content text-start"
        style={{
          padding,
          maxWidth: '800px', // Set a maximum width to constrain content
          width: '100%', // Make it responsive
        }}
      >
        <style>
          {`
            .story-text {
              font-size: ${fontSize}; /* Use the passed fontSize prop */
              line-height: 2.5; /* Set line height for better readability */
              text-align: left; /* Align text to the left */
              word-wrap: break-word; /* Force words to break within the container */
              white-space: normal; /* Ensure the text wraps to the next line */
            }
          `}
        </style>
        <div
          className="story-text"
          style={{ fontSize }}
          dangerouslySetInnerHTML={{ __html: content }} // Render HTML content
        />
      </div>
    </div>
  );
};

export default StoryContent;
