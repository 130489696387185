// src/pages/About.tsx
import React from 'react';
import { Container, Row } from 'react-bootstrap';
import AboutContent from './AboutContent'; // Adjust the import path as necessary
import AboutStatistics from './AboutStatistics'; // Import the AboutStatistics component

const About: React.FC = () => {
  return (
    <Container fluid className="py-4">
      <Row className="justify-content-center">
        <AboutContent /> {/* Using the AboutContent component here */}
      </Row>
      <Row className="justify-content-center">
        <AboutStatistics /> {/* Include the AboutStatistics component here */}
      </Row>
    </Container>
  );
};

export default About;
