import React, { useEffect, useState } from 'react';
import { Form, Button, Container, Row, Col, Toast } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { subscribeToNewsletter } from '../../Common/realBackend/subscribeToNewsletter';

const inputStyle = {
  backgroundColor: '#374460',
  border: '1px solid rgba(255, 255, 255, 0.5)',
  color: '#FFFFFF',
  borderRadius: '0',
  padding: '15px',
  width: '100%',
};

const NewsletterSubscription: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 576);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 576);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const validationSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
  });

  const formik = useFormik({
    initialValues: { name: '', email: '' },
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const response = await subscribeToNewsletter({
          email: values.email,
          firstName: values.name,
        });
        setToastMessage('Subscription successful!');
        setShowToast(true);
      } catch (error) {
        console.error('Subscription error:', error);
        setToastMessage('This email is already subscribed');
        setShowToast(true);
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <>
      <Container className="newsletter-subscription py-5 my-4" style={{ backgroundColor: '#984DE5', borderRadius: '8px', padding: '0 20px' }}>
        <Row className="align-items-center justify-content-between">
          <Col xs={12} md={4} className={`text-md-start text-center mb-3 mb-md-0`}>
            <h3 className="nba-font mb-0 text-white" style={{
              fontSize: isMobile ? '3rem' : '3.5rem',
              lineHeight: isMobile ? '1.2' : '1.5',
              textAlign: isMobile ? 'center' : 'left',
            }}>
              NEVER MISS A NEW POST
            </h3>
          </Col>
          <Col xs={12} md={8} className="d-flex justify-content-center justify-content-md-end">
            <Form onSubmit={formik.handleSubmit} className="d-flex flex-column flex-md-row align-items-center w-100">
              <Form.Group controlId="formName" className="mb-3 me-2 w-75 w-md-25">
                <Form.Control
                  type="text"
                  name="name"
                  placeholder="Enter your name"
                  className={`custom-input ${formik.touched.name && formik.errors.name ? 'is-invalid' : ''}`}
                  style={inputStyle}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                />
                {formik.touched.name && formik.errors.name && <div className="invalid-feedback">{formik.errors.name}</div>}
              </Form.Group>
              <Form.Group controlId="formEmail" className="mb-3 me-2 w-75 w-md-50">
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="Enter your email address"
                  className={`custom-input ${formik.touched.email && formik.errors.email ? 'is-invalid' : ''}`}
                  style={inputStyle}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                {formik.touched.email && formik.errors.email && <div className="invalid-feedback">{formik.errors.email}</div>}
              </Form.Group>
              <Button
                variant="light"
                type="submit"
                disabled={loading}
                style={{
                  backgroundColor: '#FFFFFF',
                  borderColor: '#FFFFFF',
                  color: '#984DE5',
                  fontWeight: 'bold',
                  padding: '10px 20px',
                  borderRadius: '0',
                }}
              >
                {loading && <div className="spinner-border spinner-border-sm text-primary me-2" role="status"><span className="visually-hidden">Loading...</span></div>}
                Subscribe
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>

      <Toast
        onClose={() => setShowToast(false)}
        show={showToast}
        delay={3000}
        autohide
        style={{
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          backgroundColor: toastMessage === 'Subscription successful!' ? '#28a745' : '#dc3545',
          color: 'white',
        }}
      >
        <Toast.Body>{toastMessage}</Toast.Body>
      </Toast>

      <style>
        {`
          @media (max-width: 767.98px) {
            h3 {
              font-size: 2.5rem;
            }
            .custom-input {
              font-size: 1rem;
            }
            .btn {
              padding: 0.5rem 1rem;
            }
          }
        `}
      </style>
    </>
  );
};

export default NewsletterSubscription;
