import React, { useState } from 'react';
import { Modal, Form, Button, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { loginUser } from '../../Common/realBackend/login';

interface LoginModalProps {
    show: boolean;
    onHide: () => void;
    onLoginSuccess: () => void;
    openSignUpModal: () => void;
}

const LoginModal: React.FC<LoginModalProps> = ({ show, onHide, onLoginSuccess, openSignUpModal }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleLogin = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);
    setError(null);
    setSuccessMessage(null);

    try {
      const response = await loginUser({ email, password });
      if (response.status === 'success') {
        localStorage.setItem('authResponse', JSON.stringify(response));
        setSuccessMessage('Login successful!');
        setTimeout(() => {
          onLoginSuccess();
          onHide();
        }, 1000);
      } else {
        setError(response.message);
      }
    } catch (err) {
      console.error('Login error:', err);
      setError('Failed to log in. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Sign In</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && <Alert variant="danger">{error}</Alert>}
        {successMessage && <Alert variant="success">{successMessage}</Alert>}

        <Form onSubmit={handleLogin}>
          <Form.Group controlId="formBasicEmail" className="mb-3">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group controlId="formBasicPassword" className="mb-3">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </Form.Group>

          <Button variant="primary" type="submit" className="w-100 mb-3" disabled={loading}>
            {loading ? 'Signing in...' : 'Sign In'}
          </Button>
        </Form>

        <div className="text-center mt-3">
          <span style={{ color: '#007bff' }}>
            Don't have an account?{' '}
            <a
                href="#/"
                style={{ color: '#007bff' }}
                onClick={(e) => {
                    e.preventDefault();
                    console.log('Sign Up link clicked'); // Log when the link is clicked
                    onHide(); // Close the Login modal first
                    openSignUpModal(); // Directly open the Sign Up modal after hiding the Login modal
                }}
            >
                Sign Up
            </a>

          </span>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default LoginModal;
