import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';

interface VideoContentProps {
  videoLink: string; // Accepts the full video link
}

const VideoContent: React.FC<VideoContentProps> = ({ videoLink }) => {
  const [loading, setLoading] = useState(true); // Loading state

  // This function will be called when the video is loaded
  const handleVideoLoad = () => {
    setLoading(false); // Set loading to false when video is loaded
  };

  useEffect(() => {
    setLoading(true); // Set loading to true when video link changes
  }, [videoLink]);

  return (
    <div className="video-content text-center my-4">
      {loading && (
        <div className="spinner-container">
          <Spinner animation="border" role="status" />
        </div>
      )}
      <iframe
        width="1000"
        height="400"
        src={videoLink.replace("watch?v=", "embed/")} // Convert the watch URL to embed URL
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        onLoad={handleVideoLoad} // Call this when the video is loaded
      ></iframe>

      <style>
        {`
          .spinner-container {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 400px; /* Match the height of the video */
          }
        `}
      </style>
    </div>
  );
};

export default VideoContent;
