import { fetchData } from "../helpers/api_helper";

export interface ReplyResponse {
  status: string;
  message: string;
  data: {
    user: { username: string };
  };
}

export const createReply = async (commentId: string, payload: { content: string }): Promise<ReplyResponse> => {
  try {
    const url = `/api/v1/comments/comment/${commentId}/reply`;
    return await fetchData(url, { method: "POST", body: JSON.stringify(payload) });
  } catch (error) {
    console.error("Failed to create reply:", error);
    throw error;
  }
};
