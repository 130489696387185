import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { loginUser } from '../../Common/realBackend/login'; // Import the login API function

const Login: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate(); // Initialize useNavigate

  const handleLogin = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);
    setError(null);
    setSuccessMessage(null);

    try {
      const response = await loginUser({ email, password });
      if (response.status === 'success') {
        localStorage.setItem('authResponse', JSON.stringify(response));
        setSuccessMessage('Login successful!');
        setTimeout(() => {
          navigate('/'); // Redirect to the home route
        }, 1000); // Optional delay to display the success message
      } else {
        setError(response.message);
      }
    } catch (err) {
      console.error('Login error:', err);
      setError('Failed to log in. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container fluid className="py-5" style={{ color: 'white' }}>
      <Row className="justify-content-center">
        <Col xs={12} md={6} lg={4}>
          <h2 className="text-center mb-4">Sign In</h2>

          {/* Display success or error messages */}
          {error && <Alert variant="danger">{error}</Alert>}
          {successMessage && <Alert variant="success">{successMessage}</Alert>}

          <Form onSubmit={handleLogin}>
            <Form.Group controlId="formBasicEmail" className="mb-3">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formBasicPassword" className="mb-3">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <div className="d-flex justify-content-end mt-2">
                <a href="/forgot-password" style={{ color: 'lightblue', fontSize: '0.9rem' }}>
                  Forgot password?
                </a>
              </div>
            </Form.Group>

            <Button variant="primary" type="submit" className="w-100 mb-3" disabled={loading}>
              {loading ? 'Signing in...' : 'Sign In'}
            </Button>
          </Form>

          <div className="text-center mt-3">
            <span style={{ color: 'white' }}>
              Don't have an account?{' '}
              <a href="#/signup" style={{ color: 'lightblue' }}>
                Sign Up
              </a>
            </span>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
