import React from 'react';
import { Container, Navbar, Nav } from 'react-bootstrap';
import { FaCommentDots } from 'react-icons/fa';
import { useNavigate, useLocation } from 'react-router-dom';
import Logo from "../assets/images/Adebowale-logo.png";

const Header = () => {

  const navigate = useNavigate();
  const location = useLocation();

  const handleScrollToContact = () => {
    // Check if already on the Home page
    if (location.pathname === '/#') {
      // Scroll to contact section
      const contactSection = document.getElementById('contact-us');
      if (contactSection) {
        contactSection.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      // Navigate to Home and then scroll after rendering
      navigate('/#', { replace: true });
      setTimeout(() => {
        const contactSection = document.getElementById('contact-us');
        if (contactSection) {
          contactSection.scrollIntoView({ behavior: 'smooth' });
        }
      }, 100); // Delay to allow page to render
    }
  };

  return (
    <Navbar expand="lg" style={{ backgroundColor: 'transparent', padding: '20px 0', zIndex: 2, position: 'relative' }}>
      <Container className="d-flex justify-content-between align-items-center">
        
        {/* Left - Logo */}
        <Navbar.Brand href="#/" style={{ color: '#fff', fontWeight: 'bold' }}>
          <img
            src={Logo} // Use the imported logo
            alt="Logo"
            className="logo" // Add a class for styling
          />
        </Navbar.Brand>

        {/* Hamburger Icon and Menu Text */}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span className="menu-text">Menu</span>
          <Navbar.Toggle aria-controls="basic-navbar-nav" style={{ border: 'none' }} />
        </div>

        {/* Center - Links with "/" separator */}
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mx-auto d-flex align-items-center">
            <Nav.Link href="#/" style={{ color: '#fff', fontWeight: 'normal' }}>Home</Nav.Link>
            <span className="separator">/</span> {/* Separator */}
            <Nav.Link href="#/about" style={{ color: '#fff', fontWeight: 'normal' }}>About</Nav.Link>
            <span className="separator">/</span> {/* Separator */}
            <Nav.Link href="#/book" style={{ color: '#fff', fontWeight: 'normal' }}>Books</Nav.Link>
          </Nav>
          
          {/* Centered - Message Icon with Let's Talk inside the menu */}

          <div className="d-flex align-items-center justify-content-center">
            <a 
              onClick={handleScrollToContact} 
              style={{ color: '#fff', textDecoration: 'none', display: 'flex', alignItems: 'center', cursor: 'pointer' }}
            >
              <div
                className="d-flex align-items-center justify-content-center"
                style={{
                  width: '30px',
                  height: '30px',
                  borderRadius: '50%',
                  border: '2px solid #fff',
                  marginRight: '10px',
                }}
              >
                <FaCommentDots style={{ color: '#fff', fontSize: '0.9rem' }} />
              </div>
              <span style={{ fontWeight: 'normal' }}>Let's Talk</span>
            </a>
          </div>
        </Navbar.Collapse>
      </Container>

      {/* Inline Styles */}
      <style>{`
        .navbar-nav .nav-link {
          margin: 0 10px;
        }

        .navbar-brand {
          display: flex;
          align-items: center;
        }

        .logo {
          width: 140px; /* Default logo size */
          margin-right: 10px;
        }

        .separator {
          color: #fff; /* Color for the separator */
          margin: 0 10px; /* Space around the separator */
        }

        /* Change color of the hamburger icon */
        .navbar-toggler {
          border-color: rgba(255, 255, 255, 0.5); /* Set border color */
        }

        .navbar-toggler-icon {
          background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30' fill='white'%3E%3Cpath stroke='rgba(255,255,255,1)' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
        }

        /* Hide separators in mobile view */
        @media (max-width: 991.98px) {
          .separator {
            display: none; /* Hide separator for mobile */
          }

          .menu-text {
            display: block; /* Show menu text in mobile view */
            color: #fff; /* Ensure text is white */
            margin-left: 5px; /* Add some margin */
          }

          /* Reduce logo size for mobile */
          .logo {
            width: 100px; /* Smaller logo size for mobile */
          }
        }

        @media (min-width: 992px) {
          .menu-text {
            display: none; /* Hide menu text in desktop view */
          }
        }
      `}</style>
    </Navbar>
  );
};

export default Header;
