// src/components/PageTransition.tsx
import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useLocation } from 'react-router-dom';

type PageTransitionProps = {
  children: React.ReactNode;
};

const PageTransition: React.FC<PageTransitionProps> = ({ children }) => {
  const location = useLocation();

  // Page flip animation settings for a book-like effect
  const pageVariants = {
    hidden: {
      scale: 1, // Keep scale at 1 for natural flipping
      opacity: 0,
      rotateY: 90, // Start with a 90-degree flip on the Y axis
      transition: { duration: 0.6, ease: 'easeInOut' }, // Exit duration
    },
    visible: {
      scale: 1,
      opacity: 1,
      rotateY: 0, // No rotation when visible
      transition: { duration: 0.6, ease: 'easeInOut', delay: 0.6 }, // Delay to start after the exit animation
    },
    exit: {
      scale: 1, // Keep scale at 1 for natural flipping
      opacity: 0,
      rotateY: -90, // Exit with a -90-degree flip on the Y axis
      transition: { duration: 0.6, ease: 'easeInOut' },
    },
  };

  return (
    <AnimatePresence mode="wait">
      {/* Old page exit */}
      <motion.div
        key={location.pathname}
        variants={pageVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
        style={{ position: 'absolute', width: '100%', height: '100%' }} // Ensure full width and height
      >
        {children}
      </motion.div>
    </AnimatePresence>
  );
};

export default PageTransition;
