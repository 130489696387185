// getBookById.ts

import { fetchData } from "../helpers/api_helper";
import { Book } from "./getAllBooks"; // Import Book interface from the getAllBooks file

// Define the structure for the API response
interface GetBookByIdResponse {
  status: string;
  data: {
    book: Book;
  };
}

// Function to fetch a book by its ID
export const getBookById = async (id: number): Promise<Book> => {
  try {
    const response: GetBookByIdResponse = await fetchData(`/api/v1/books/getBookById/${id}`);
    return response.data.book;
  } catch (error) {
    console.error(`Failed to fetch book with ID ${id}:`, error);
    throw error;
  }
};
