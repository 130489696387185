import React, { useEffect, useState } from 'react';
import { Container, Table, Form, Row, Col } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { getAllBookEpisodes, Episode } from '../../Common/realBackend/getAllBookEpisodes';

const inputStyle = {
  backgroundColor: '#374460',
  border: '1px solid rgba(255, 255, 255, 0.5)',
  color: '#FFFFFF',
  borderRadius: '0',
  padding: '10px',
  fontSize: '1rem',
  width: '100%',
};

// Float-in-from-bottom animation variants with staggered delay
const floatInFromBottomVariants = {
  initial: {
    opacity: 0,
    y: 50, // Start from below the screen
  },
  animate: (i: number) => ({
    opacity: 1,
    y: 0, // Float to its original position
    transition: {
      delay: i * 0.2, // Stagger the animation for each element
      duration: 1,
      ease: 'easeInOut',
    },
  }),
  exit: {
    opacity: 0,
    y: 50, // Move down when leaving
    transition: {
      duration: 0.5,
      ease: 'easeInOut',
    },
  },
};

const SeriesList: React.FC = () => {
  const { bookId } = useParams<{ bookId: string }>();
  const [episodes, setEpisodes] = useState<Episode[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<null | string>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEpisodes = async () => {
      try {
        const episodeData = await getAllBookEpisodes(Number(bookId));
        setEpisodes(episodeData);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch episodes');
        setLoading(false);
      }
    };
    fetchEpisodes();
  }, [bookId]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const handleEpisodeClick = (episodeId: string) => {
    navigate(`/story-details/series/${bookId}/${episodeId}`);
  };

  const filteredEpisodes = episodes.filter((item) =>
    item.title.toLowerCase().includes(searchTerm)
  );

  if (loading) return <Container>Loading...</Container>;
  if (error) return <Container>{error}</Container>;

  return (
    <motion.div initial="initial" animate="animate" exit="exit">
      <Container>
        <style>
          {`
          .custom-heading { font-size: 5rem; text-align: left; color: #fff; }
          .series-table { background-color: transparent; }
          .series-table th, .series-table td { background-color: transparent !important; border-bottom: none !important; vertical-align: middle; color: #fff; }
          .series-table tbody tr:hover { background-color: #1F2A40 !important; color: #fff !important; cursor: pointer; }
          .series-image { width: 50px; height: 50px; object-fit: cover; margin-right: 10px; border-radius: 10%; }
          .form-control::placeholder { color: white; }
          `} 
        </style>

        {/* Episode Title with float animation */}
        <motion.h1
          className="nba-font my-4 custom-heading"
          variants={floatInFromBottomVariants}
          custom={0}
        >
          EPISODES
        </motion.h1>

        {/* Search bar with float animation */}
        <motion.div variants={floatInFromBottomVariants} custom={1}>
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group controlId="search">
                <Form.Control
                  type="text"
                  placeholder="Search by title"
                  value={searchTerm}
                  onChange={handleSearch}
                  style={inputStyle}
                />
              </Form.Group>
            </Col>
          </Row>
        </motion.div>

        {/* Table container starts after a 2-second delay */}
        <motion.div
          variants={floatInFromBottomVariants}
          custom={5}
        >
          <Table hover responsive variant="dark" className="series-table">
            <thead>
              <tr>
                <th>Episode</th>
                <th>Title & Image</th>
              </tr>
            </thead>
            <tbody>
              {filteredEpisodes.length > 0 ? (
                filteredEpisodes.map((item, index) => (
                  <motion.tr
                    key={item.id}
                    onClick={() => handleEpisodeClick(item.id.toString())}
                    variants={floatInFromBottomVariants}
                    custom={index * 5} // Adjust the staggered delay for each row
                  >
                    <td style={{ verticalAlign: 'middle' }}>{index + 1}</td>
                    <td style={{ verticalAlign: 'middle' }}>
                      <img
                        src={item.coverImage || ''}
                        alt={item.title}
                        className="series-image"
                      />
                      {item.title}
                    </td>
                  </motion.tr>
                ))
              ) : (
                <tr>
                  <td colSpan={2} style={{ textAlign: 'center', color: '#fff' }}>
                    No episodes available
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </motion.div>
      </Container>
    </motion.div>
  );
};

export default SeriesList;
