import { fetchData } from "../helpers/api_helper";

// Define the Episode interface here
interface Episode {
  id: number;
  title: string;
  description: string;
  audioLink?: string; // Optional if some episodes might not have audio
  videoLinkString?: string; // Optional if some episodes might not have video
  coverImage: string;
  date: string;
  type: string; // type can be "one-shot" or "series" depending on your app
}

// Define the structure for the API response
interface GetEpisodeByIdResponse {
  status: string;
  data: {
    episode: Episode;
  };
}

// Function to fetch an episode by its ID
export const getEpisodeById = async (bookId: number, episodeId: number): Promise<Episode> => {
  try {
    const response: GetEpisodeByIdResponse = await fetchData(`/api/v1/books/${bookId}/episodes/${episodeId}`);
    return response.data.episode;
  } catch (error) {
    console.error(`Failed to fetch episode with ID ${episodeId} for book ID ${bookId}:`, error);
    throw error;
  }
};
