import { fetchData } from "../helpers/api_helper";

// Define the structure for the login payload
interface LoginPayload {
  email: string;
  password: string;
}

// Define the structure for the API response
interface LoginResponse {
  status: string;
  message: string;
}

// Function to log in a user
export const loginUser = async (payload: LoginPayload): Promise<LoginResponse> => {
  try {
    // Adjust the fetchData call according to its expected parameters
    const response: LoginResponse = await fetchData("/api/v1/users/login", {
      method: "POST",
      body: JSON.stringify(payload),
      headers: { "Content-Type": "application/json" }
    });
    return response;
  } catch (error) {
    console.error("Failed to log in user:", error);
    throw error;
  }
};
