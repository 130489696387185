import React, { useEffect, useState } from 'react';
import 'react-h5-audio-player/lib/styles.css';
import AudioPlayer from 'react-h5-audio-player';

interface AudioPlayerProps {
  audioSrc: string;
}

const CustomAudioPlayer: React.FC<AudioPlayerProps> = ({ audioSrc }) => {
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 576); // Mobile state
  const [playerWidth, setPlayerWidth] = useState<number>(500); // Default width for the player

  // Update isMobile and playerWidth based on screen width
  useEffect(() => {
    const handleResize = () => {
      const newWidth = window.innerWidth <= 576 ? window.innerWidth - 40 : 500; // Adjust width for mobile
      setIsMobile(window.innerWidth <= 576);
      setPlayerWidth(newWidth);
    };
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="custom-audio-player">
      <AudioPlayer
        src={audioSrc}
        showJumpControls={true} // Show the forward and backward controls
        customAdditionalControls={[]} // Optional: Remove additional controls if not needed
        customVolumeControls={[]} // Optional: Customize volume controls
        layout="horizontal" // Optional: Change the layout if needed
      />
      
      {/* Inline styles for the audio player */}
      <style>{`
        .custom-audio-player {
          width: ${playerWidth}px; /* Use state for responsive width */
          margin-left: 0; /* Align to the left */
        }

        /* Customize button colors */
        .rhap_container .rhap_play,
        .rhap_container .rhap_volume-button,
        .rhap_container .rhap_jump-button {
          background-color: #875CFF !important; /* Button background color */
          color: white !important; /* Button text color */
        }

        /* Optional: Change hover effect */
        .rhap_container .rhap_play:hover,
        .rhap_container .rhap_volume-button:hover,
        .rhap_container .rhap_jump-button:hover {
          background-color: #6f4efc !important; /* Darker shade on hover */
        }

        @media (max-width: 767.98px) {
          .custom-audio-player {
            width: 100%; /* Full width on mobile */
            padding: 0 10px; /* Optional: Add some padding */
          }
        }
      `}</style>
    </div>
  );
};

export default CustomAudioPlayer;
