import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { getAllBooks, Book } from '../../Common/realBackend/getAllBooks'; // Import API function and Book interface

const TwoCardLayout: React.FC = () => {
  const navigate = useNavigate();
  const [books, setBooks] = useState<Book[]>([]);
  const [currentSeriesIndex, setCurrentSeriesIndex] = useState(0);
  const [currentOneShortIndex, setCurrentOneShortIndex] = useState(0);

  useEffect(() => {
    const fetchBooks = async () => {
      try {
        const booksData = await getAllBooks();
        setBooks(booksData);
      } catch (error) {
        console.error("Error fetching books:", error);
      }
    };
    fetchBooks();
  }, []);

  // Separate series and one-shots based on type (using 'series' and 'one-short' types)
  const seriesBooks = books.filter(book => book.type === 'series');
  const oneShortBooks = books.filter(book => book.type === 'one-short');

  useEffect(() => {
    const interval = setInterval(() => {
      if (seriesBooks.length > 0) {
        setCurrentSeriesIndex(prevIndex => (prevIndex + 1) % seriesBooks.length);
      }
      if (oneShortBooks.length > 0) {
        setCurrentOneShortIndex(prevIndex => (prevIndex + 1) % oneShortBooks.length);
      }
    }, 5000);
    return () => clearInterval(interval);
  }, [seriesBooks.length, oneShortBooks.length]);
  
  const handleSeriesClick = (id: number) => {
    navigate(`/series-list/${id}`);
  };

  const handleCardClick = (id: number) => {
    navigate(`/story-details/one-short/${id}`);
  };

  const handleCategoryClick = (category: string) => {
    // Navigate to the category page (series or one-short)
    navigate(`/story-list/${category}`);
  };

  return (
    <Container className="my-5 py-5">
      <h2 className="text-center mb-2 text-white fw-bold">My Work</h2>
      <div className="text-center mb-5">
        <a href="#/story-list" className="view-all-link">View All</a>
      </div>
      <Row>
        {/* Series Card */}
        <Col md={6} className="d-flex flex-column align-items-center mb-4 mb-md-0">
          {seriesBooks.length > 0 && (
            <Card
              className="text-center position-relative w-100"
              style={{ border: 'none', cursor: 'pointer' }}
              onClick={() => handleSeriesClick(seriesBooks[currentSeriesIndex].id)}
            >
              <Card.Img
                variant="top"
                src={seriesBooks[currentSeriesIndex].coverImage || ''}
                style={{ width: '100%', height: '400px', objectFit: 'cover' }}
              />
              <div className="gradient-overlay" />
              <Card.Title className="text-white position-absolute bottom-0 start-0 m-3 title-overlay">
                {seriesBooks[currentSeriesIndex].title}
              </Card.Title>
            </Card>
          )}
          <div className="d-flex justify-content-between align-items-center mt-2 w-100">
            <h5 
              className="my-0 nba-font text-white" 
              style={{ cursor: 'pointer' }}
              onClick={() => handleCategoryClick('series')} // Navigate to series list page
            >
              SERIES
            </h5>
            <div className="d-flex align-items-center">
              <span onClick={() => setCurrentSeriesIndex(prev => (prev - 1 + seriesBooks.length) % seriesBooks.length)} className="arrow text-white" style={{ cursor: 'pointer', fontSize: '1.5rem' }}>{'←'}</span>
              <span onClick={() => setCurrentSeriesIndex(prev => (prev + 1) % seriesBooks.length)} className="arrow text-white ml-2" style={{ cursor: 'pointer', fontSize: '1.5rem' }}>{'→'}</span>
            </div>
          </div>
        </Col>

        {/* One-Short Card */}
        <Col md={6} className="d-flex flex-column align-items-center">
          {oneShortBooks.length > 0 && (
            <Card
              className="text-center position-relative w-100"
              style={{ border: 'none', cursor: 'pointer' }}
              onClick={() => handleCardClick(oneShortBooks[currentOneShortIndex].id)} // Pass the ID here
            >
              <Card.Img
                variant="top"
                src={oneShortBooks[currentOneShortIndex].coverImage || ''}
                style={{ width: '100%', height: '400px', objectFit: 'cover' }}
              />
              <div className="gradient-overlay" />
              <Card.Title className="text-white position-absolute bottom-0 start-0 m-3 title-overlay">
                {oneShortBooks[currentOneShortIndex].title}
              </Card.Title>
            </Card>
          )}
          <div className="d-flex justify-content-between align-items-center mt-2 w-100">
            <h5 
              className="my-0 nba-font text-white" 
              style={{ cursor: 'pointer' }}
              onClick={() => handleCategoryClick('one-short')} // Navigate to one-shots list page
            >
              ONE-SHOTS
            </h5>
            <div className="d-flex align-items-center">
              <span onClick={() => setCurrentOneShortIndex(prev => (prev - 1 + oneShortBooks.length) % oneShortBooks.length)} className="arrow text-white" style={{ cursor: 'pointer', fontSize: '1.5rem' }}>{'←'}</span>
              <span onClick={() => setCurrentOneShortIndex(prev => (prev + 1) % oneShortBooks.length)} className="arrow text-white ml-2" style={{ cursor: 'pointer', fontSize: '1.5rem' }}>{'→'}</span>
            </div>
          </div>
        </Col>
      </Row>

      {/* CSS Styles */}
      <style>{`
        .nba-font {
          font-size: 2rem; /* Increased font size */
          font-weight: bold;
        }
        .ml-2 {
          margin-left: 8px; /* Adds spacing between the arrows */
        }
        .card {
          padding: 0; /* Remove padding from cards */
          margin: 0; /* Remove margin from cards */
        }

        /* Gradient Overlay Styles */
        .gradient-overlay {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.56) 56%, rgba(0, 0, 0, 0) 100%);
          border-radius: 0.375rem; /* Match the card's border radius */
          z-index: 1; /* Ensure the overlay appears above the image */
        }

        /* Title Overlay Style */
        .title-overlay {
          z-index: 2; /* Ensure the title appears above the overlay */
          font-size: 2.5rem; /* Increased font size */
          font-weight: normal; /* Set font weight to regular */
          text-transform: uppercase; /* Make text all caps */
          overflow: hidden; /* Hide overflow */
          white-space: nowrap; /* Prevent wrapping */
          text-overflow: ellipsis; /* Show ellipsis if text overflows */
          max-width: 90%; /* Limit width to avoid cutting off too early */
        }

        /* Responsive Styles */
        @media (max-width: 768px) {
          .title-overlay {
            font-size: 1.5rem; /* Reduced font size for mobile */
          }

          /* Adjust Card Image Height for Mobile */
          .card img {
            height: 450px; /* Increase height for mobile cards */
          }
        }

        /* View All Link Style */
        .view-all-link {
          color: #984DE5;
          font-weight: normal; /* Changed to regular font weight */
          text-decoration: none;
          position: relative;
        }

        .view-all-link::after {
          content: '';
          position: absolute;
          left: 0;
          bottom: -2px;
          width: 0;
          height: 2px;
          background-color: #984DE5;
          transition: width 0.3s ease;
        }

        .view-all-link:hover::after {
          width: 100%;
        }
      `}</style>
    </Container>
  );
};

export default TwoCardLayout;
