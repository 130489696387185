import React, { useState } from 'react';
import { Modal, Form, Button, Alert } from 'react-bootstrap';
import { signUpUser } from '../../Common/realBackend/signUp'; // Import the signUpUser function
import { useNavigate } from 'react-router-dom';
import { Formik, Field, Form as FormikForm } from 'formik'; // Ensure you import these from Formik
import * as Yup from 'yup'; // You need to import Yup for validation
import { FaEye, FaEyeSlash } from 'react-icons/fa'; // Import the eye icons

interface SignUpModalProps {
  show: boolean;
  onHide: () => void;
  openLoginModal: () => void; // Function to open login modal
}

// Validation schema for the form
const SignUpSchema = Yup.object().shape({
  fullName: Yup.string().required('Full name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  password: Yup.string().min(6, 'Password must be at least 6 characters').required('Password is required'),
});

const SignUpModal: React.FC<SignUpModalProps> = ({ show, onHide, openLoginModal }) => {
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
  const navigate = useNavigate();

  const handleSignUp = async (values: any) => {
    const { fullName, email, password } = values;

    // Split the fullName into firstName and lastName
    const [firstName, ...lastNameArr] = fullName.split(' ');
    const lastName = lastNameArr.join(' ') || '';
    const userName = email.split('@')[0]; // Use the email to create a username
    const role = 'user'; // Default role for sign-up

    const payload = {
      email,
      firstName,
      lastName,
      userName,
      password,
      role,
    };

    setLoading(true);
    setError(null);
    setSuccessMessage(null);

    try {
      const response = await signUpUser(payload);
      if (response.status === 'success') {
        localStorage.setItem('auth', JSON.stringify(response));
        setSuccessMessage('User signed up successfully!');
        
        setTimeout(() => {
            openLoginModal(); // Open the login modal
            onHide(); // Close the signup modal
        }, 2000);
      } else {
        setError(response.message);
      }
    } catch (err) {
      setError('Failed to sign up. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Sign Up</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* Display error or success message */}
        {error && <Alert variant="danger">{error}</Alert>}
        {successMessage && <Alert variant="success">{successMessage}</Alert>}

        {/* Formik form for user registration */}
        <Formik
          initialValues={{ fullName: '', email: '', password: '' }}
          validationSchema={SignUpSchema}
          onSubmit={handleSignUp}
        >
          {({ errors, touched }) => (
            <FormikForm>
              <Form.Group controlId="formFullName" className="mb-3">
                <Form.Label>Full Name</Form.Label>
                <Field
                  name="fullName"
                  type="text"
                  placeholder="Enter your full name"
                  className={`form-control ${touched.fullName && errors.fullName ? 'is-invalid' : ''}`}
                />
                {touched.fullName && errors.fullName ? (
                  <div className="invalid-feedback">{errors.fullName}</div>
                ) : null}
              </Form.Group>

              <Form.Group controlId="formEmail" className="mb-3">
                <Form.Label>Email address</Form.Label>
                <Field
                  name="email"
                  type="email"
                  placeholder="Enter email"
                  className={`form-control ${touched.email && errors.email ? 'is-invalid' : ''}`}
                />
                {touched.email && errors.email ? (
                  <div className="invalid-feedback">{errors.email}</div>
                ) : null}
              </Form.Group>

              <Form.Group controlId="formPassword" className="mb-3">
                <Form.Label>Password</Form.Label>
                <div className="input-group">
                  <Field
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Password"
                    className={`form-control ${touched.password && errors.password ? 'is-invalid' : ''}`}
                  />
                  <Button
                    variant="outline-secondary"
                    onClick={() => setShowPassword(!showPassword)}
                    className="input-group-text"
                  >
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </Button>
                </div>
                {touched.password && errors.password ? (
                  <div className="invalid-feedback">{errors.password}</div>
                ) : null}
              </Form.Group>

              <Button
                variant="primary"
                type="submit"
                className="w-100 mb-3"
                disabled={loading}
              >
                {loading ? 'Signing up...' : 'Sign Up'}
              </Button>
            </FormikForm>
          )}
        </Formik>

        <div className="text-center mt-3">
          <span>
            Already have an account?{' '}
            <a
                href="#"
                style={{ color: '#007bff', textDecoration: 'none' }} // Customize the color of the link here
                onClick={(e) => {
                    e.preventDefault(); // Prevent the default anchor tag behavior
                    openLoginModal(); // Trigger the openLoginModal function
                }}
            >
                Log In
            </a>

          </span>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SignUpModal;
