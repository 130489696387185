import { fetchData } from "../helpers/api_helper";

// Define the structure for the signup payload
interface SignUpPayload {
  email: string;
  firstName: string;
  lastName: string;
  userName: string;
  password: string;
  role: string;
}

// Define the structure for the API response
interface SignUpResponse {
  status: string;
  message: string;
}

// Function to sign up a user
export const signUpUser = async (payload: SignUpPayload): Promise<SignUpResponse> => {
  try {
    // Adjust the fetchData call according to its expected parameters
    const response: SignUpResponse = await fetchData("/api/v1/users/signup", {
      method: "POST",
      body: JSON.stringify(payload),
      headers: { "Content-Type": "application/json" }
    });
    return response;
  } catch (error) {
    console.error("Failed to sign up user:", error);
    throw error;
  }
};
