import React, { useState } from 'react';
import { Col, Row, Form, Button, Toast } from 'react-bootstrap';
import { Formik, Field, Form as FormikForm } from 'formik';
import * as Yup from 'yup';
import contactImage from '../../assets/images/memoji.png';
import { createContact } from '../../Common/realBackend/contact'; // Import the API function

// Validation schema for the form
const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  subject: Yup.string().required('Subject is required'),
  message: Yup.string().required('Message is required'),
});

// Custom styles for placeholder and inputs
const inputStyle = {
  backgroundColor: '#374460',
  border: '1px solid rgba(255, 255, 255, 0.5)',
  color: '#FFFFFF',
  borderRadius: '0',
};

// Placeholder CSS styles
const placeholderStyle = `
  .custom-input::placeholder {
    color: white;
    opacity: 0.7;
  }
`;

const ContactUs: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);

  return (
    <div className="py-5">
      <style>{placeholderStyle}</style> {/* Inject placeholder styles */}
      <h2 className="text-center mb-4 text-white fw-bold">Get In Touch</h2>
      <p className="text-center mb-5 text-white" style={{ fontSize: '1.0rem' }}>
        We would love to hear from you! Please fill out the form below and we’ll get back to you as soon as possible.
      </p>

      <Row className="align-items-center">
        <Col md={4} className="d-flex justify-content-center order-md-0 order-1">
          <img 
            src={contactImage} 
            alt="Contact Us" 
            className="img-fluid" 
            style={{ maxHeight: '300px', width: 'auto', maxWidth: '100%', height: 'auto' }} 
          />
        </Col>

        <Col md={8} className="order-md-1 order-0">
          <Formik
            initialValues={{ name: '', email: '', subject: '', message: '' }}
            validationSchema={validationSchema}
            onSubmit={async (values, { resetForm }) => {
              setLoading(true);

              try {
                await createContact(values); // Call the createContact API function
                setLoading(false);
                setShowToast(true);
                resetForm(); // Reset form on successful submission
              } catch (error) {
                console.error("Failed to send contact message:", error);
                setLoading(false);
              }
            }}
          >
            {({ handleSubmit }) => (
              <FormikForm onSubmit={handleSubmit}>
                <Row className="mb-3">
                  <Form.Group as={Col} xs={12} md={6} controlId="formName" className="mb-3 mb-md-0">
                    <Field 
                      name="name" 
                      as={Form.Control} 
                      type="text" 
                      placeholder="Name" 
                      className="custom-input" 
                      style={inputStyle} 
                    />
                  </Form.Group>
                  <Form.Group as={Col} xs={12} md={6} controlId="formEmail">
                    <Field 
                      name="email" 
                      as={Form.Control} 
                      type="email" 
                      placeholder="Email" 
                      className="custom-input" 
                      style={inputStyle} 
                    />
                  </Form.Group>
                </Row>

                <Form.Group className="mb-3" controlId="formSubject">
                  <Field 
                    name="subject" 
                    as={Form.Control} 
                    type="text" 
                    placeholder="Subject" 
                    className="custom-input" 
                    style={inputStyle} 
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formMessage">
                  <Field 
                    name="message" 
                    as="textarea" 
                    className="form-control custom-input" 
                    rows={5} 
                    placeholder="Your Message" 
                    style={inputStyle} 
                  />
                </Form.Group>

                <Button 
                  variant="primary" 
                  type="submit" 
                  style={{ 
                    width: '100%', 
                    backgroundColor: '#984DE5', 
                    borderColor: '#984DE5', 
                    fontWeight: 'bold',
                    display: 'flex', 
                    justifyContent: 'center', 
                    alignItems: 'center'
                  }}
                  disabled={loading}
                >
                  {loading && (
                    <div className="spinner-border spinner-border-sm text-light me-2" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}
                  Send
                </Button>
              </FormikForm>
            )}
          </Formik>
        </Col>
      </Row>

      {/* Toast for success notification */}
      <Toast
        onClose={() => setShowToast(false)}
        show={showToast}
        delay={3000}
        autohide
        style={{
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          backgroundColor: '#28a745',
          color: 'white',
        }}
      >
        <Toast.Body>Message sent successfully!</Toast.Body>
      </Toast>
    </div>
  );
};

export default ContactUs;
