// bookApi.ts

import { fetchData } from "../helpers/api_helper";

// Define the structure for an Episode
export interface Episode {
  id: number;
  title: string;
  description: string;
  videoLink: string | null;
  audioLink: string | null;
  videoLinkString: string | null;
  audioLinkString: string | null;
  coverImage: string | null;
  story: string;
  overallGlobalReadersCount: number;
  createdAt: string;
  updatedAt: string;
  bookId: number;
}

// Define the structure for the API response for episodes
interface GetEpisodesResponse {
  status: string;
  data: {
    episodes: Episode[];
  };
}

// Function to fetch all episodes for a specific book
export const getAllBookEpisodes = async (bookId: number): Promise<Episode[]> => {
  try {
    const response: GetEpisodesResponse = await fetchData(`/api/v1/books/${bookId}/episodes`);
    return response.data.episodes;
  } catch (error) {
    console.error(`Failed to fetch episodes for book ${bookId}:`, error);
    throw error;
  }
};
