// src/App.tsx
import React from 'react';
import Route from './Routes/index';
import ScrollToTop from './components/ScrollToTop';

function App() {
  return (
    <React.Fragment>
      <ScrollToTop />
      <Route />
    </React.Fragment>
  );
}

export default App;
